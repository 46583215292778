<template>
  <a-modal :visible.sync="show"
           title="设置商品当前库存"
           destroyOnClose
           width="400px"
           @ok="handleOk"
           @cancel="$emit('update:show', false)">
    <div class="modal-search-bar">
      <div class="flex items-center">
        <!-- <span>总库存</span> -->
        <span class="ml-3" style="min-width:200px;">
          <a-input v-model="totalStock" placeholder="批量设置下列仓库的库存" />
        </span>
        <a-button class="ml-3"
                  type="primary"
                  @click="handerAllStock">全部应用</a-button>
      </div>
      <div class="mt-3">
        <a-table size="small"
                 rowKey="virtual_warehouse_id"
                 :data-source="stockList"
                 bordered
                 :pagination="false">
          <a-table-column width="150"
                          title="仓库名称"
                          dataIndex="virtual_warehouse_name"
                          algin="center"></a-table-column>
          <a-table-column title="库存"
                          width="300"
                          dataIndex="current_stock"
                          algin="center">
            <template slot-scope="text, record">
              <div class="flex items-center">
                <a-input v-model="record.current_stock"
                         style="width:100px;" />
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </a-modal>

</template>

<script>

import { getCurrentStockBySku, editGoodsCurrentStock } from '@/api/warehouse'

export default {
  props: {
    show: {
      type: Boolean,
    },
    skuId: {
      type: String,
    }
  },
  data () {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      totalStock: "",
      stockList: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      let id = this.skuId
      if (id.includes(',')) {
        id = this.skuId.split(',')[0]
      }
      const { data, code } = await getCurrentStockBySku({ sku_id: id })
      if (code === 0) {
        this.stockList = data.list
      }
    },
    // 保存
    handleOk () {
      
      const params = {
        sku_ids: this.skuId,
        current_stock_data: JSON.stringify(this.stockList)
      }
      editGoodsCurrentStock(params).then(res => {
        if (res.code === 0) {
          this.$message.success('更新当前库存成功！')
          this.$emit('update:show', false)
          this.$emit('ok')
        }
      })
    },

    // 全部库存应用
    handerAllStock () {
      this.totalStock = parseInt(this.totalStock) || 0
      this.stockList = this.stockList.map(item => {
        item.current_stock = this.totalStock
        return item
      })
      // this.$confirm({
      //   title: '提示',
      //   content: '将当前库存全部设置为 '+this.totalStock+' ？',
      //   okText: '确认',
      //   cancelText: '取消',
      //   onOk: () => {
      //     this.stockList = this.stockList.map(item => {
      //       item.current_stock = this.totalStock
      //       return item
      //     })
      //     this.handleOk()
      //   }
      // });
    },

  }
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>