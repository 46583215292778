import ajax from '@/utils/ajax.js'

/**
 * 获取仓库列表
 * @param {*} params 
 * @returns 
 */
export function getWarehouseList(params) {
  return ajax.post('/VirtualWarehouse/getList',params)
}


/**
 * 添加仓库
 * @param {*} params
 * @returns 
 */
 export function addWarehouse(params) {
  return ajax.post('/VirtualWarehouse/add',params)
}

/**
 * 修改仓库
 * @param {*} params
 * @returns 
 */
 export function editWarehouse(params) {
  return ajax.post('/VirtualWarehouse/update',params)
}


/**
 * 修改仓库
 * @param {*} params
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/VirtualWarehouse/updateStatus',params)
}


/**
 * 获取仓库库存列表
 * @param {*} params
 * @returns 
 */
 export function getStockGoodsList(params) {
  return ajax.post('/AllVirtualWarehouseGoodsSkuStock/getList',params)
}

/**
 * 获取仓库库存
 * @param {*} params
 * @returns 
 */
 export function getStockBySku(params) {
  return ajax.post('/AllVirtualWarehouseGoodsSkuStock/getGoodsStock',params)
}

/**
 * 获取当前仓库库存
 * @param {*} params
 * @returns 
 */
 export function getCurrentStockBySku(params) {
  return ajax.post('/AllVirtualWarehouseGoodsSkuStock/getCurrentStock',params)
}


/**
 * 修改仓库库存
 * @param {*} params
 * @returns 
 */
 export function editGoodsStock(params) {
  return ajax.post('/AllVirtualWarehouseGoodsSkuStock/updateAllGoodsStock',params)
}

/**
 * 修改仓库当前库存
 * @param {*} params
 * @returns 
 */
 export function editGoodsCurrentStock(params) {
  return ajax.post('/AllVirtualWarehouseGoodsSkuStock/updateAllCurrentStock',params)
}


/***********  仓库商品    ******************* */


/**
 * 获取当前仓库库存
 * @param {*} params
 * @returns 
 */
 export function getWarehouseGoodsSkuList(params) {
  return ajax.post('/VirtualWarehouseGoodsSkuStock/getList',params)
}


/**
 * 修改仓库商品SKU库存
 * @param {*} params
 * @returns 
 */
 export function editWarehouseGoodsSkuStock(params) {
  return ajax.post('/VirtualWarehouseGoodsSkuStock/updateAllGoodsStock',params)
}

/**
 * 修改仓库商品当前库存
 * @param {*} params
 * @returns 
 */
 export function editWarehouseGoodsCurrentSkuStock(params) {
  return ajax.post('/VirtualWarehouseGoodsSkuStock/updateAllCurrentStock',params)
}

// ================================= 城市工厂（真实仓库）

/**
 * 工厂列表
 * @param {*} params
 * @returns 
 */
export function getFactoryList(params) {
  return ajax.post('/WdtWarehouse/getList2',params)
}

/**
 * 工厂列表（覆盖范围）
 * @param {*} params
 * @returns 
 */
export function getFactoryAreaList(params) {
  return ajax.post('/WdtWarehouse/getList',params)
}

/**
 * 城市工厂-修改
 * @param {*} params
 * @returns 
 */
export function updateFactory(params) {
  return ajax.post('/WdtWarehouse/update',params)
}

// ================================= 快递运费（边远地区）
/**
 * 快递运费（边远地区）-列表
 * @param {*} params
 * @returns 
 */
export function getExpressFreightList(params) {
  return ajax.post('/ExpressFreight/getList',params)
}
/**
 * 快递运费（边远地区）-添加
 * @param {*} params
 * @returns 
 */
export function addExpressFreight(params) {
  return ajax.post('/ExpressFreight/add',params)
}
/**
 * 快递运费（边远地区）-修改
 * @param {*} params
 * @returns 
 */
export function updateExpressFreight(params) {
  return ajax.post('/ExpressFreight/update',params)
}
/**
 * 快递运费（边远地区）-修改状态
 * @param {*} params
 * @returns 
 */
export function updateStatusExpressFreight(params) {
  return ajax.post('/ExpressFreight/updateStatus',params)
}
