import { goodsType, formatGoodsType } from "@/utils/type";

export const rowKey = 'sku_id'
export const columns = [
  {
    title: '产品属性',
    dataIndex: 'goods_type',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatGoodsType(val)
  },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center'
  },
  {
    title: '口味',
    dataIndex: 'flavor_name',
    align: 'center',
  },
  {
    title: '尺寸',
    dataIndex: 'specification_name',
    align: 'center',
  },
  // {
  //   title: '商品库存',
  //   dataIndex: 'goods_stock',
  //   width: 80,
  //   align: 'center',
  //   slots: {
  //     customRender: 'goods_stock'
  //   }
  // },
  // {
  //   title: '当前库存',
  //   dataIndex: 'current_stock',
  //   width: 80,
  //   align: 'center',
  //   slots: {
  //     customRender: 'current_stock'
  //   }
  // },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
    align: "center",
    width: 80,
  },
]

export default {
  rowKey,
  columns,
  goodsType
}
