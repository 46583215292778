<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :wrapperCol="{ span: 18 }"
        :labelCol="{ span: 6 }"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <!-- <a-col :span="6">
            <a-form-model-item prop="goods_number" label="商品编码">
              <a-input allowClear v-model="searchForm.goods_number" placeholder="请输入商品编码"></a-input>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="8">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="type" label="产品属性">
              <a-select v-model="searchForm.type" placeholder="请选择产品属性">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="sku_number" label="SKU码">
              <a-input allowClear v-model="searchForm.sku_number" placeholder="请输入SKU码"></a-input>
            </a-form-model-item>
          </a-col>


        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="flavor_name" label="口味">
              <a-input allowClear v-model="searchForm.flavor_name" placeholder="请输入口味"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item prop="specification_name" label="尺寸">
              <a-input allowClear v-model="searchForm.specification_name" placeholder="请输入尺寸"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <div class="text-right mt-1">
              <a-checkbox v-model="isOnlyOnSale">仅看商城上架的</a-checkbox>
              <a-button type="primary" html-type="submit" @click="initData">搜索</a-button>
              <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <base-table
      ref="tableRef"
      :columnsData="stockColumns"
      :rowKey="rowKey"
      :tableData="tableData"
      :total="total"
      :row-selection="rowSelection"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">商品的虚拟仓库库存</h4>
          <div>
            <!-- <a-button type="primary" @click="handlerEditMany('all')" html-type="submit">批量商品库存</a-button> -->
            <a-button
              class="ml-3"
              type="primary"
              @click="handlerEditMany('current')"
              html-type="submit"
            >批量当前库存</a-button>
          </div>
        </div>
      </template>
      <!-- <template #goods_stock="{record}">
        <a-button type="link" @click="handlerEdit(record,'all')">编辑</a-button>
      </template> -->
      <template #operation="{record}">
        <a-button type="link" @click="handlerEdit(record,'current')">编辑</a-button>
      </template>
    </base-table>
    <!-- <edit-stock-modal v-if="isShowEdit" :show.sync="isShowEdit" :skuId="activeSkuId" /> -->
    <current-stock-modal
      v-if="isShowEditCurrent"
      :show.sync="isShowEditCurrent"
      :skuId="activeSkuId"
      @ok="initData"
    />
  </div>
</template>

<script>
// import EditStockModal from "./components/edit-stock-modal.vue"
import { getStockGoodsList } from "@/api/warehouse"
import pageData from "./columns"
import CurrentStockModal from "./components/current-stock-modal.vue"
export default {
  components: { 
    // EditStockModal, 
    CurrentStockModal 
  },
  data() {
    return {
      ...pageData,
      wrapperCol: { span: 19 },
      labelCol: { span: 5 },
      total: 0,
      stockColumns: [],
      tableData: [],
      searchForm: {
        goods_number: "",
        goods_name: "",
        sku_number: "",
        type: "",
        flavor_name: "",
        specification_name: "",
        is_on_sale: 2,
        page: 1,
        page_count: 10,
      },
      isOnlyOnSale: true,

      rowKeys: [],
      rowSelection: {
        rowKeys: [],
        onChange(selectedRowKeys) {
          this.rowKeys = selectedRowKeys
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows)
        },
      },
      isShowEdit: false,
      isShowEditCurrent: false,
      activeSkuId: 0,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      this.searchForm.is_on_sale = this.isOnlyOnSale?2:1

      const { data, code } = await getStockGoodsList(this.searchForm)
      if (code === 0) {
        const whcolumns = []
        if(data.list[0]){
          data.list[0].virtual_warehouse_data.map(wh=>{
            whcolumns.push({
              title: wh.virtual_warehouse_name,
              dataIndex: "vwh_" + wh.virtual_warehouse_id,
              align: 'center',
              width: 96,
            })
          })
          data.list.map(el=>{
            el.virtual_warehouse_data.map(wh=>{
              el["vwh_" + wh.virtual_warehouse_id] = wh.current_stock
            })
          })
        }
        let baseColumns = this.columns.concat([])
        const operation = baseColumns.pop()
        baseColumns = baseColumns.concat(whcolumns)
        baseColumns.push(operation)
        this.stockColumns = baseColumns
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
      this.initData()
    },

    handlerEdit(row, type) {
      this.activeSkuId = row.sku_id.toString()
      if (type === "all") this.isShowEdit = true
      else this.isShowEditCurrent = true
    },
    handlerEditMany(type) {
      if (this.rowSelection.rowKeys.length === 0) {
        this.$message.warning("请先勾选需要设置库存的商品！")
        return
      }
      this.activeSkuId = this.rowSelection.rowKeys.join(",")
      if (type === "all") this.isShowEdit = true
      else this.isShowEditCurrent = true
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>